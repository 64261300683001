import React from 'react';
import { Text } from 'wix-ui-tpa';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { AccessibilityHtmlTags } from '../../../../utils/accessibility/constants';
import { HeaderViewModel } from '../../ViewModel/headerViewModel/headerViewModel';
import { st, classes } from './Header.st.css';
import Filters from './Filters/Filters';
import TimezoneSelection from '../TimezoneSelection/TimezoneSelection';
import settingsParams from '../../settingsParams';

export type HeaderProps = {
  viewModel: HeaderViewModel;
};

const Header: React.FC<HeaderProps> = ({ viewModel }) => {
  const { isMobile, isRTL } = useEnvironment();
  const settings = useSettings();
  const {
    title,
    subtitle,
    isSubtitleVisible,
    isFiltersVisible,
    filters,
    timezoneSelectionViewModel,
  } = viewModel;
  const shouldShowSubtitle = isSubtitleVisible && subtitle;

  return (
    <div
      data-hook="header"
      className={st(classes.root, {
        isRTL,
        isMobile,
        alignment: settings.get(settingsParams.textAlignment),
      })}
    >
      <Text
        data-hook="header-title"
        className={classes.title}
        tagName={AccessibilityHtmlTags.MainHeading}
      >
        {title}
      </Text>
      {shouldShowSubtitle ? (
        <Text
          data-hook="header-subtitle"
          className={classes.subtitle}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {subtitle}
        </Text>
      ) : null}
      {isFiltersVisible ? <Filters filterViewModels={filters} /> : null}
      {timezoneSelectionViewModel ? (
        <TimezoneSelection viewModel={timezoneSelectionViewModel} />
      ) : null}
    </div>
  );
};

export default Header;
